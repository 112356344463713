import React from "react";
import { PageProps } from "gatsby";
import useLatestData from "../utils/useLatestData";
import { SanityPerson, SanityRibbon } from "../../graphql-types";
import { HomePageGrid } from "../styles/Grids";
import LoadingGrid from "../components/LoadingGrid";
import ItemGrid from "../components/ItemGrid";

interface ICurrentBebeletes {
  bebeletes: SanityPerson[];
}

function CurrentBebeletes({ bebeletes }: ICurrentBebeletes) {
  return (
    <div>
      <h2 className="center">
        <span className="mark tilt">Bebeletes</span>
      </h2>
      <p>Ours Bebeletes</p>
      {!bebeletes && <LoadingGrid count={4} />}
      {bebeletes && !bebeletes?.length && <p>No one is working right now!</p>}
      {bebeletes?.length && <ItemGrid items={bebeletes} />}
    </div>
  );
}

interface IHotRibbons {
  hotRibbons: SanityRibbon[];
}

function HotRibbons({ hotRibbons }: IHotRibbons) {
  return (
    <div>
      <h2 className="center">
        <span className="mark tilt">Ribbons</span>
      </h2>
      <p>The magic is here!</p>
      {!hotRibbons && <LoadingGrid count={4} />}
      {hotRibbons && !hotRibbons?.length && <p>No one is working right now!</p>}
      {hotRibbons?.length && <ItemGrid items={hotRibbons} />}
    </div>
  );
}

export default function HomePage(props: PageProps) {
  const { hotRibbons, bebeletes } = useLatestData();
  return (
    <div className="center">
      <h1>The bests ribbons in Portugal!</h1>
      <p>online all the time</p>
      <HomePageGrid>
        <CurrentBebeletes bebeletes={bebeletes} />
        <HotRibbons hotRibbons={hotRibbons} />
      </HomePageGrid>
    </div>
  );
}
