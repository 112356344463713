import React, { Children, ReactElement } from "react";
import { SanityPerson, SanityRibbon } from "../../graphql-types";
import { ItemsGrid, ItemStyles } from "../styles/Grids";

interface IItemGrid {
  items: SanityRibbon[] | SanityPerson[];
}

export default function ItemGrid({ items }: IItemGrid) {
  return (
    <ItemsGrid>
      {items.map((item) => (
        <ItemStyles key={item.id}>
          <p>
            <span className="mark">{item.name}</span>
          </p>
          <img
            src={`${item.image.asset.url}?w=500&h=400&fit=crop`}
            alt="Loading"
            width="500"
            height="400"
            style={{
              background: `url(${item.image.asset.metadata.lqip})`,
              backgroundSize: "cover",
            }}
          />
        </ItemStyles>
      ))}
    </ItemsGrid>
  );
}
