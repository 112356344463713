import React, { useEffect, useState } from "react";
import { SanityPerson, SanityRibbon } from "../../graphql-types";

const gql = String.raw;
const Deets = `
    _id
    name
    image {
      asset {
        url
        metadata {
          lqip
        }
      }
    }
`;
const STORE_SETTINGS_QUERY = gql`
  query {
    StoreSettings(id: "downtown") {
      bebeletes {
       ${Deets}
      }
      hotRibbons {
        ${Deets}
      }
    }
  }
`;

interface IResponse {
  data: {
    StoreSettings: {
      hotRibbons: SanityRibbon[];
      bebeletes: SanityPerson[];
    };
  };
}

export default function useLatestData() {
  const [hotRibbons, setHotRibbons] = useState<SanityRibbon[]>([]);
  const [bebeletes, setBebeletes] = useState<SanityPerson[]>([]);

  useEffect(function () {
    fetch(process.env.GATSBY_GRAPHQL_ENDPOINT, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        query: STORE_SETTINGS_QUERY,
      }),
    })
      .then((res) => res.json())
      .then((res: IResponse) => {
        setHotRibbons(res.data.StoreSettings.hotRibbons);
        setBebeletes(res.data.StoreSettings.bebeletes);
      });
  }, []);

  return {
    hotRibbons,
    bebeletes,
  };
}
